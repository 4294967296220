import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { TextField, Card } from "@mui/material";
import { GetServerSideProps } from "next";
import ConfirmBorrow from "../component/ConfirmBorrow";
import ContextApp from "../component/ContextApp";
import ItemSet from "../component/ItemSet";
import { setsDB } from "../data/set";
import { ISet } from "../mobx/globalStore";
import { ModalMB } from "../mobx/modal";
import Head from "next/head";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  justify-items: center;
  gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1450px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const SearchBar = styled(Card)`
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
  max-width: 300px;
`;

const CountryPage = ({ sets }: { sets: ISet[] }) => {
  const context = useContext(ContextApp);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [modal] = useState(() => new ModalMB());
  const [sizeArr, setSizeArr] = useState(30);

  context.setSets(sets);

  useEffect(() => {
    const handleScroll = (e: Event) => {
      if (
        document.documentElement.offsetHeight -
          (window.outerHeight + window.scrollY) <
        window.innerHeight
      )
        setSizeArr((prev) => prev + 30);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Head>
        <title>Wypożyczalnia klocków - Polska</title>
        <meta
          name="description"
          content="Największa wypożyczalnia klocków lego w polsce. Posiadamy ponad 500 różnych zestawów, a nasze magazyny z roku na rok się powiększają! Jesteśmy dostępni w całej Polsce."
        />
        <meta
          name="keyword"
          content="wypożyczalnia klocków, wypożyczalnia klocków lego"
        />
      </Head>
      <ConfirmBorrow modal={modal} />
      <SearchBar>
        <TextField
          label="ID LEGO"
          variant="standard"
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
        <TextField
          label="Nazwa"
          variant="standard"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </SearchBar>
      <Grid>
        {sets
          .filter((x) => x.name.toLowerCase().includes(title.toLowerCase()))
          .filter((x) => x.id_lego.toString().includes(id))
          .filter((x, i) => i < sizeArr)
          .map((v) => {
            return <ItemSet key={v.id_lego} set={v} modal={modal} />;
          })}
      </Grid>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const sets = await setsDB();
  return {
    props: {
      sets: sets,
    },
  };
};

export default observer(CountryPage);
